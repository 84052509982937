<template>
  <PrimaryTemplate
    :step="step"
    :buttonFunction="submit"
    :mainTitle="'Basic Information'"
    :needBackButton="false"
    :loading="loading"
  >
    <BirthDayComponent
      labelText="Date of Birth"
      v-model="birthDate"
      :validationMessage="errorMessages.birthDate"
    />

    <TextInput
      name="adress"
      labelText="City"
      placeholder="Enter your city"
      v-model="address"
      :validationMessage="errorMessages.address"
    />
    <TextInput
      name="number"
      labelText="Phone Number"
      placeholder="Enter your phone number"
      v-model="number"
      processOn="change"
      :validationMessage="errorMessages.number"
    />
    <TextInput
      name="skype"
      labelText="Skype"
      placeholder="Enter your skype"
      v-model="skype"
    />
    <TextInput
      name="github-link"
      labelText="GitHub"
      placeholder="Enter your GitHub"
      v-model="github"
      processOn="change"
      :validationMessage="errorMessages.github"
    />
    <div v-if="errorMessage" class="error-message red">
      {{ errorMessage }}
    </div>
  </PrimaryTemplate>
</template>
<script>
import PrimaryTemplate from "@/layouts/PrimaryTemplate.vue";
import TextInput from "../components/utility/TextInput";
import BirthDayComponent from "@/components/utility/BirthDayComponent.vue";
import { update } from "../api/formdata";
import { digitsPlus, noDigits } from "../validations/custom";

import useVuelidate from "@vuelidate/core";
import {
  url,
  minLength,
  // required,
  helpers,
  maxLength,
} from "@vuelidate/validators";

import { getErrorMessage } from "../utils/getErrorMessage.js";

// const isDateValid = (date) => {
//   return /^\d{4}-\d{2}-\d{2}$/.test(date);
// };

export default {
  name: "BasicInfo",
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      step: "BasicInfo",
      errorMessage: null,
      loading: false,
    };
  },
  computed: {
    isValid: function () {
      return !this.v$.$invalid;
    },
    birthDate: {
      get() {
        const birthDate = this.$store.getters.getBirthDate;
        return birthDate.length === 0 ? "--" : birthDate;
      },
      set(value) {
        if (value !== null) {
          this.$store.dispatch("setSecondStepField", {
            field: "birthDate",
            value: value,
          });
        }
      },
    },
    number: {
      get() {
        return this.$store.getters.getNumber;
      },
      set(value) {
        this.$store.dispatch("setSecondStepField", {
          field: "number",
          value: value,
        });
      },
    },
    address: {
      get() {
        return this.$store.getters.getAddress;
      },
      set(value) {
        this.$store.dispatch("setSecondStepField", {
          field: "address",
          value: value,
        });
      },
    },
    skype: {
      get() {
        return this.$store.getters.getSkype;
      },
      set(value) {
        this.$store.dispatch("setSecondStepField", {
          field: "skype",
          value: value,
        });
      },
    },
    github: {
      get() {
        return this.$store.getters.getGithub;
      },
      set(value) {
        this.$store.dispatch("setSecondStepField", {
          field: "github",
          value: value,
        });
      },
    },
    socialNetwork: {
      get() {
        return this.$store.getters.getSocialNetwork;
      },
      set(value) {
        this.$store.dispatch("setSecondStepField", {
          field: "socialNetwork",
          value: value,
        });
      },
    },
    errorMessages: function () {
      return {
        // birthDate: getErrorMessage(this.v$.birthDate.$errors),
        github: getErrorMessage(this.v$.github.$errors),
        socialNetwork: getErrorMessage(this.v$.socialNetwork.$errors),
        number: getErrorMessage(this.v$.number.$errors),
        address: getErrorMessage(this.v$.address.$errors),
      };
    },
  },
  methods: {
    async submit() {
      this.v$.$touch();
      let modifyLoading = this.modifyLoading;
      let setError = this.setError;
      let router = this.$router;
      if (!this.v$.$invalid) {
        modifyLoading(true);
        setError(null);

        let thisData = {
          birthdate: this.birthDate,
          phone: this.number,
          city: this.address,
          skype: this.skype,
          github: this.github,
        };
        let currentData = JSON.parse(localStorage.getItem("data"));
        currentData.data.push(thisData);

        await update(currentData).then((response) => {
          if (response.status >= 200 && response.status <= 300) {
            modifyLoading(false);

            let thisDataStringified = JSON.stringify(currentData);
            localStorage.setItem("data", thisDataStringified);
            router.push({ path: "/expectations" });
          } else {
            modifyLoading(false);
            if (response.status == 400) {
              setError(
                "Validation error! Please check the accuracy of the entered data!"
              );
            } else {
              setError("Form submission error! Please try again later!");
            }
            console.log("Error: ");
            console.log(response.status);
          }
        });
      }
    },
    modifyLoading(loading) {
      this.loading = loading;
    },
    setError(message) {
      this.errorMessage = message;
    },
  },
  components: {
    PrimaryTemplate,
    TextInput,
    BirthDayComponent,
  },
  validations() {
    return {
      // birthDate: {
      //   required,
      //   isDateValid: helpers.withMessage("Date is not valid", isDateValid),
      //   $autoDirty: true,
      // },
      github: {
        url,
        $autoDirty: true,
      },
      socialNetwork: {
        url,
        $autoDirty: true,
      },
      number: {
        digitsPlus: helpers.withMessage(
          "Only numbers and + are allowed",
          digitsPlus
        ),
        minLength: minLength(9),
        maxLength: maxLength(50),
        $autoDirty: true,
      },
      address: {
        noDigits: helpers.withMessage("Digits are not allowed", noDigits),
        maxLength: maxLength(50),
        $autoDirty: true,
      },
    };
  },
};
</script>
<style>
.red {
  color: red;
}
:disabled {
  opacity: 0.4;
}
</style>
