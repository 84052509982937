<template>
  <div
    class="custom-vue-select"
    :class="{
      active: modelValue !== '',
      disabled: !disabled,
    }"
  >
    <vue-select
      :options="options"
      :placeholder="placeholder"
      :modelValue="modelValue"
      :close-on-select="true"
      :disabled="disabled"
      @selected="handleSelected"
      @removed="handleRemoved"
    ></vue-select>
  </div>
</template>

<script>
import VueSelect from "vue-next-select";

export default {
  name: "CustomVueSelect",
  props: {
    options: Array,
    placeholder: String,
    modelValue: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],

  methods: {
    handleSelected(value) {
      this.$emit("update:modelValue", value);
    },
    handleRemoved() {
      this.$emit("update:modelValue", "");
    },
  },

  components: {
    VueSelect,
  },
};
</script>

<style lang="scss" scoped>
// File is attached as _build in App.vue
// @import "../../assets/scss/CustomVueSelect.scss";
</style>
