import { helpers } from "@vuelidate/validators";

const digitsPlus = (value) => {
  var thisRegex = new RegExp("^[0-9\\+]+$");
  return !helpers.req(value) || thisRegex.test(value);
};

const noDigits = (value) => {
  var thisRegex = new RegExp("[^\\d]+$");
  return !helpers.req(value) || thisRegex.test(value);
};

export { digitsPlus, noDigits };
