<template>
  <div class="birthday-component">
    <div class="small-title">
      <InputLabel :id="id" :text="labelText" :mandatory="mandatory" />
      <InputValidationMessage :valid="isValid" :message="validationMessage" />
    </div>
    <div class="row-wrapper">
      <div class="input-wrapper">
        <InputText
          name="birth-day"
          v-model="day"
          placeholder="Day"
          processOn="change"
        />
        <div class="validation-message-wrapper">
          <InputValidationMessage :message="errorMessages.day" />
        </div>
      </div>
      <div class="input-wrapper">
        <CustomVueSelect
          :options="monthNames"
          placeholder="Month"
          v-model="monthString"
        />
        <div class="validation-message-wrapper">
          <InputValidationMessage :message="errorMessages.month" />
        </div>
      </div>
      <div class="input-wrapper">
        <InputText
          name="birth-year"
          v-model="year"
          placeholder="Year"
          processOn="change"
        />
        <div class="validation-message-wrapper">
          <InputValidationMessage :message="errorMessages.year" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { generateUID } from "../../utils/generateUID";

import InputLabel from "../elements/InputLabel";
import InputText from "../elements/InputText";
import InputValidationMessage from "../elements/InputValidationMessage";
import CustomVueSelect from "../elements/CustomVueSelect";

import useVuelidate from "@vuelidate/core";
import { between, maxValue, minValue } from "@vuelidate/validators";
import { getErrorMessage } from "../../utils/getErrorMessage.js";

export default {
  name: "BirthDayComponent",
  props: {
    birthDate: String,
    labelText: String,
    mandatory: Boolean,
    modelValue: {
      type: String,
      default: "",
    },
    validationMessage: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      id: generateUID(),
      day: this.parseDate()[2],
      monthString: "",
      year: this.parseDate()[0],
      months: {
        January: "01",
        February: "02",
        March: "03",
        April: "04",
        May: "05",
        June: "06",
        July: "07",
        August: "08",
        September: "09",
        October: "10",
        November: "11",
        December: "12",
      },
    };
  },
  computed: {
    month: function () {
      return this.monthString !== "" ? this.months[this.monthString] : "";
    },
    dateString: function () {
      return `${this.year}-${this.month}-${this.day}`;
    },
    isValid: function () {
      return this.v$.$invalid;
    },
    monthNames: function () {
      return Object.keys(this.months);
    },
    errorMessages: function () {
      return {
        day: getErrorMessage(this.v$.day.$errors),
        month: getErrorMessage(this.v$.month.$errors),
        year: getErrorMessage(this.v$.year.$errors),
      };
    },
    allFieldsAreValid: function () {
      // Vuelidate checks validations asynchroniously, so we just wait untill everything is fine
      if (this.v$.day && this.v$.month && this.v$.year) {
        return (
          !this.v$.day.$invalid &&
          !this.v$.month.$invalid &&
          !this.v$.year.$invalid
        );
      }
      return false;
    },
  },
  methods: {
    handleMonthStringChange(value) {
      this.month = this.getMonthNumber(value);
    },
    parseDate() {
      // 1900-12-31 : YYYY-MM-DD
      return this.modelValue.split("-");
    },
  },
  watch: {
    day: function () {
      this.day = this.day.length === 1 ? "0" + this.day : this.day;
    },
    allFieldsAreValid: function (value) {
      // Await untill Vuelidate calculates and updated all validators
      // When done and no errors - update parent model
      if (value === true) {
        this.$emit("update:modelValue", this.dateString);
      }
    },
  },
  created() {
    // Parse 02 to Февраль when correct data comes in props
    const monthNumber = this.parseDate()[1];
    const parsedMonths = JSON.parse(JSON.stringify(this.months));

    if (monthNumber.length !== 0) {
      for (const [key, value] of Object.entries(parsedMonths)) {
        if (value === monthNumber) {
          this.monthString = key;
        }
      }
    }
  },
  components: {
    InputLabel,
    InputText,
    CustomVueSelect,
    InputValidationMessage,
  },
  validations() {
    return {
      day: {
        between: between(1, 31),
        $autoDirty: true,
      },
      month: {
        $autoDirty: true,
      },
      year: {
        minValue: minValue(1900),
        maxValue: maxValue(2030),
        $autoDirty: true,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";

.birthday-component {
  display: flex;
  flex-direction: column;
  max-width: 630px;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  @media (max-width: 768px) {
    max-width: 100%;
  }

  .small-title {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .row-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .input-wrapper {
      width: 100%;
      max-width: 32%;

      .validation-message-wrapper {
        min-height: 25px;
      }

      @media screen and (max-width: 679.88px) {
        min-width: 100%;
      }
    }

    .day-input,
    .year-input {
      max-width: 190px;
      width: 100%;
      height: 50px;
      border: 1px solid $main-grey;
      border-radius: 10px;
      padding: 0 20px;
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-size: 14px;
      line-height: 17px;
      box-sizing: border-box;
      -moz-appearance: textfield;
      @media (min-width: 320px) and (max-width: 375px) {
        padding: 0 15px;
      }
      &::placeholder {
        color: $main-grey;
        font-weight: bold;
      }

      &:focus {
        border-color: $template-color;
      }
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
}
</style>
